<template>
    <!-- Comum para todas as páginas do de-para -->
    <LayoutTelas id="tela__depara" :titulo="this.$route.meta.titulo">
      <div>
        <!-- <div>Tela de manutenção para a tabela de-para de Conta Contábil.</div> -->
        <loading
          :active.sync="dados.isLoading"
          :can-cancel="false"
          :is-full-page="true"
          color="#f68b29"/>

        <div class='botoes'>
          <b-button
            type="button"
            variant="primary"
            size="sm"
            class="btnProcessar"
            :disabled="dados.disabledCampos"
            @click="dados.filtro_opened = !dados.filtro_opened"><v-icon scale="0.8" name="filter"/> Filtro</b-button>

          <b-button
            type="button"
            variant="success"
            size="sm"
            class="btnAdicionar"
            @click="abrirJanelaModal()"
            :disabled="dados.disabledCampos"><v-icon scale="0.8" name="plus"/> Adicionar</b-button>
        </div>
        
        <div v-if="dados.filtro_opened">
          <b-form class="filtro" @submit.prevent="listarDados(1)">
            <InputBasico
              label="ID Usuário"
              name="fieldIdUser"
              size="sm"
              v-model="dados.item.idUser"
              :disabled="dados.disabledCampos"
              type="text"
              class="inputClassificacao"/>            
            <InputBasico
              label="Nome"
              name="fieldNome"
              size="sm"
              v-model="dados.item.nome"
              :disabled="dados.disabledCampos"
              type="text"
              class="inputConta"/>
            <InputBasico
              label="Login"
              name="fieldLogin"
              size="sm"
              v-model="dados.item.login"
              :disabled="dados.disabledCampos"
              type="text"
              class="inputClassificacao"/>

            <b-button
              type="submit"
              variant="primary"
              size="sm"
              class="btnProcessar"
              :disabled="dados.disabledCampos"><v-icon scale="0.8" name="search"/> Buscar</b-button>
          </b-form>
        </div>
              
        <label v-if="dados.isConcluido" class="concluido loaderCheck">Concluído com sucesso!</label>

        <div class="loader">
          <Processando v-if="dados.show_loader_erp" mensagem="Carregando dados da Base..."/>
          <Processando v-if="dados.show_processing" mensagem="Processando requisição..."/>
        </div>

        <div v-if="dados.show_loader_erp !== true">
          <h5>Resultado da busca</h5>

          <div class="resultado" v-if="dados.total > 0">
            {{dados.total > 1 ? 'Encontradas':'Encontrada'}} <strong>{{dados.total}}</strong> {{dados.total > 1 ? 'ocorrências':'ocorrência'}}.
          </div>
          <div v-if="dados.lista.length > 0">
            <b-table
              striped
              hover
              bordered
              small
              stacked="md"
              head-variant="dark"
              v-if="dados.show_loader_erp !== true"
              :items="dados.lista"
              :fields="dados.fieldsTable"
              @row-clicked="selecionouLinha">

              <template #head(actions)="row">
                <center>Ações</center>
              </template>

              <template #head(admin)="row">
                <center>Administrador?</center>
              </template>

              <template #cell(admin)="row">
                <center>
                  {{row.item.admin ? "Sim":"Não"}}
                </center>
              </template>

              <template #cell(actions)="row">
                <center>
                  <b-button class="btn btn-danger btnAction" size="sm" @click="excluirLinha(`${row.item._id}`)"><v-icon scale="0.8" name="trash"/></b-button>
                </center>
              </template>
            </b-table>

            <div id="footerTable">
              <div>
                <b-form-select v-model="paginacao.limitePorPagina" size="sm" :options="paginacao.limitOptions" @change="listarDados(1)"></b-form-select>
              </div>
              
              <div class="paginacao" v-if="dados.lista.length > 0">
                <b-pagination
                  class="paginacao__seletor"
                  :total-rows="paginacao.totalDePaginas"
                  :per-page="paginacao.limitePorPagina"
                  v-model="paginacao.paginaAtual"
                  @input="mudarPagina"
                  size="sm"/>
              </div>      
            </div>
          </div>
          <div v-else>
            Nenhum registro encontrado.
          </div>
        </div>

        <b-modal
          id="modal-adicionar"
          ref="modal-adicionar"
          :title="dados.itemSel._id !== undefined ? 'Alterar':'Adicionar'"
          @hidden="hiddeModal"
          @show="showModal"
          @ok="handleOk">

          <b-form ref="refDeParamensagem" @submit.stop.prevent="handleSubmit">
            <InputBasico
              ref="refNome"
              label="Nome"
              name="fieldNome"
              size="sm"
              v-model="dados.itemSel.nome"
              type="text"
              class="inputDeParaMensagem"
              :disabled="dados.disabledCampos"/>
            <InputBasico
              ref="refLogin"
              label="Login"
              name="fieldLogin"
              size="sm"
              v-model="dados.itemSel.login"
              type="text"
              info="Deverá ser informado uma chave unica para cada usuário. Exemplo: endereço de e-mail"
              class="inputDeParaMensagem"
              :disabled="dados.disabledCampos"/>
            <InputBasico
              ref="refLogin"
              label="Senha"
              name="fieldSenha"
              size="sm"
              v-model="dados.itemSel.senha"
              type="password"
              info="Caso não seja informado uma senha, seguirá com o valor padrão: 1234, porém sugerimos que seja informado uma senha complexa(alterando entre numeros e caracteres)."
              class="inputDeParaMensagem"
              :disabled="dados.disabledCampos"/>
            <label for="admin">Administrador?</label><br>  
            <toggle-button 
              id="admin" 
              :labels="{checked: 'On', unchecked: 'Off'}"
              v-model="dados.itemSel.admin"/>  

            <div class="processarCard">
              <b-card no-body >
                <b-card-header header-tag="header" class="p-1" role="tab">
                  Permissões
                </b-card-header>
                
                <b-card-body>
                  <b-form-checkbox-group
                    id="checkbox-group-1"
                    v-model="dados.itemSel.permissoes"
                    :options="dados.permissoes"
                    name="flavour-1"></b-form-checkbox-group>
                </b-card-body>
              </b-card>
            </div>

            <div class="processarCard">
              <b-card no-body >
                <b-card-header header-tag="header" class="p-1" role="tab">
                  Empresas
                </b-card-header>
                
                <b-card-body>
                  <b-form-checkbox-group
                    id="checkbox-group-2"
                    v-model="dados.itemSel.empresas"
                    :options="dados.empresas"
                    stacked
                    name="flavour-2"></b-form-checkbox-group>
                </b-card-body>
              </b-card>
            </div>
          </b-form>

          <Processando v-if="dados.show_processing_modal" mensagem="Processando requisição..."/>

          <b-alert v-model="dados.modalError" variant="danger" dismissible>
            {{dados.erroModal}}
          </b-alert>

          <template #modal-footer="{ cancel }">
            <b-button size="sm" variant="danger" @click="cancel()" :disabled="dados.disabledCampos">Cancelar</b-button>
            <b-button size="sm" variant="success" @click="handleOk" :disabled="dados.disabledCampos">Salvar</b-button>
          </template>
        </b-modal>
        <!-- <ModalAdicionar id="modal-adicionar" :selecionado="dados.selecionado" :resetFn="adicionar"/> -->
      </div>
    </LayoutTelas>
</template>

<script>
  import {
      getListaUsuario,
      addUsuario,
      delUsuario,
      updUsuario
  } from '@/common/services/geralServices';
  import InputBasico from '@/components/inputs/InputBasico';
  import Processando from '@/components/comuns/Processando';
  import { getUserObj } from "@/common/localStorage/localStorage";
  import { ToggleButton } from 'vue-js-toggle-button';

  export default {
    name:'',
    data() {
      return {
        dados: {
          item: {
            _id : undefined,
            idUser : undefined,
            nome : undefined,
            login : undefined,
            senha : undefined,
            projeto: "CDI",
            admin : false,
            master : false,
            empresas : [],
            permissoes : []
          },
          itemSel: {},
          checked: false,
          show_loader_erp: false,
          show_processing: false,
          show_processing_modal: false,
          erroModal: '',
          modalError: false,
          disabledCampos: false,
          filtro_opened: true,
          lista: [],
          total: 0,
          key: false,
          isLoading: true,
          empresas: [],
          fieldsTable: [
            { key: "idUser", label: "ID", sortable: true },
            { key: "nome", label: "Nome", sortable: true },
            { key: "login", label: "Login", sortable: true },
            { key: "admin", label: "Administrador?", sortable: true },
            { key: "actions", label: "Ações", sortable: false }
          ],
          permissoes: [
            {text:'Editar', value:'edit'},
            {text:'Inserir', value:'insert'},
            {text:'Excluir', value:'delete'},
            {text:'Visualizar', value:'view'}
          ]
        },
        paginacao: {
          paginaAtual: 1,
          limitePorPagina: 10,
          totalDePaginas: 0,
          limitOptions: [
            { value: 10, text: '10 por página' },
            { value: 25, text: '25 por página' },
            { value: 50, text: '50 por página' },
            { value: 75, text: '75 por página' },
            { value: 100, text: '100 por página' }
          ]
        },
      }
    },
    components:{
      InputBasico,
      Processando,
      ToggleButton
    },
    async created() {
      await this.listarDados(1);

      (getUserObj()).usuario.empresas.forEach(e => {
        this.dados.empresas.push({text:e.idCliente+' - '+e.nome, value:e})
      });
    },
    methods: {
      limparItem() {
        this.dados.item = {
          _id : undefined,
          idUser : undefined,
          nome : undefined,
          login : undefined,
          senha : undefined,
          projeto: "CDI",
          admin : false,
          master : false,
          empresas : [],
          permissoes : []
        };
      },
      async listarDados(pagina) {
        this.dados.lista = [];
        this.dados.show_loader_erp = true;
        this.dados.disabledCampos = true;

        const filtro = this.dados.item;

        if (this.dados.item.idUser) {
          filtro.idUser = this.dados.item.idUser; 
        }
        if (this.dados.item.nome) {
          filtro.nome = this.dados.item.nome; 
        }
        if (this.dados.item.login) {
          filtro.login = this.dados.item.login; 
        }
        filtro.projeto = "CDI";
        filtro.limite = this.paginacao.limitePorPagina, 
        filtro.pagina = pagina;
        filtro.token = (getUserObj()).token;

        const resultado = (await getListaUsuario(filtro)).data;
        this.dados.lista = resultado.list;
        this.dados.total = resultado.total;
        this.paginacao.totalDePaginas = parseInt(this.paginacao.limitePorPagina * resultado.pages);

        this.dados.show_loader_erp = false;
        this.dados.disabledCampos = false;
        this.dados.isLoading = false;
        this.limparItem();
      },
      async salvar() {
        let body = {};
        let resultado = {};
        this.dados.show_processing_modal = true;
        this.dados.disabledCampos = true;

        //Se identificar que o _id não está vazio, envia comando para atualização
        if (this.dados.itemSel._id !== undefined) {
          resultado = await updUsuario(this.dados.itemSel);
        } else {
          this.dados.itemSel.master = false;
          this.dados.itemSel.projeto = "CDI";
          resultado = await addUsuario(this.dados.itemSel);
        }
              
        this.dados.show_processing_modal = false;
        this.dados.disabledCampos = false;
        return resultado;
      },
      confirmaExclusao() {
        let retorno = this.$bvModal.msgBoxConfirm('Deseja realmente excluir este registro?', {
          title: 'Confirmação',
          size: 'lm',
          buttonSize: 'sm',
          okVariant: 'success',
          okTitle: 'Sim',
          cancelTitle: 'Não',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true
        });
        return retorno;
      },
      async excluirLinha(id) {
        if (await this.confirmaExclusao()) {
          this.dados.disabledCampos = true;
          this.dados.show_processing = true;
          let result = await delUsuario(id);
          this.dados.disabledCampos = false;
          this.dados.show_processing = false;

          await this.listarDados(this.paginacao.paginaAtual);
        }
      },
      abrirJanelaModal(value) {
        if (!value) {
          this.dados.itemSel = {
            _id : undefined,
            idUser : 0,
            nome : undefined,
            login : undefined,
            senha : undefined,
            admin : false,
            master : false,
            empresas : [],
            permissoes : []
          };
        }
        this.$bvModal.show('modal-adicionar');
      },
      selecionouLinha(item,index,event) {
        this.dados.itemSel = item;

        this.abrirJanelaModal(this.dados.itemSel);
      },
      checkFormValidity() {
        let valid = true;
        
        if (!this.dados.itemSel.login) {
            this.dados.erroModal = 'É obrigatório preencher o campo "Login"';
            this.dados.modalError = true;
            return false;
        }

        if (!this.dados.itemSel.senha) {
            this.dados.erroModal = 'É obrigatório preencher o campo "Senha"';
            this.dados.modalError = true;
            return false;
        }
        if (!this.dados.itemSel.permissoes || (this.dados.itemSel.permissoes && this.dados.itemSel.permissoes.length == 0)) {
            this.dados.erroModal = 'É obrigatório selecionar ao menos uma permissão';
            this.dados.modalError = true;
            return false;
        }

        if (!this.dados.itemSel.empresas || (this.dados.itemSel.empresas && this.dados.itemSel.empresas.length == 0)) {
            this.dados.erroModal = 'É obrigatório selecionar ao menos uma empresa';
            this.dados.modalError = true;
            return false;
        }
        return valid
      },
      hiddeModal() {
        this.dados.modalError = false;
      },
      showModal() {
        // this.$nextTick(function () {
        //     this.$refs.refDeParamensagem_c1.focus()
        // })
      },
      handleOk(bvModalEvt) {
        // Parando processamento
        bvModalEvt.preventDefault()
        // executando o submit
        this.handleSubmit()
      },
      async handleSubmit() {
        this.dados.erroModal = '';
        this.dados.modalError = false;

        if (!this.checkFormValidity()) {
          return
        }

        //Envia dados ao servidor  
        const resp = await this.salvar();

        if (resp.data.erro) {
          this.dados.erroModal = resp.data.erro;
          this.dados.modalError = true;
        } else {
          this.listarDados(this.paginacao.paginaAtual);

          //Fecha a janela
          this.$nextTick(() => {
            this.$bvModal.hide('modal-adicionar');
          })
        }
      },
      async mudarPagina(p) {
        const {paginaAtual} = this.paginacao;
        this.listarDados(paginaAtual);
      }
    }
  }
</script>

<style lang="scss" scope>
  @import "@/common/scss/breakpoints.scss";

  #tela__depara {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    @media (min-width: $medium-size) {
      min-width: $small-size;
    }
  }

  .filtro {
    display: grid;
    grid-template-columns: 1fr;
    
    @media (min-width: $medium-size) {
      width: 100%;
      grid-template-columns: repeat(6, 1fr);
      grid-column-gap: 0.8em;
    }
  }

  .resultado {
    font-size: 12px;    
    @media (min-width: $medium-size) {
      width: 30%;
    }
  }

  .botoes {
    display: grid;
    width: 100%;
    align-items: center; /* left and right */
    justify-content: end; /* up and down */
  }

  .botoes button {
    margin-left: 10px;
    width: 120px
  }

  .btn {
    &Processar {
      @media (min-width: $medium-size) {
        grid-column: 1 / 2;
        margin-top: 0.2rem;
        margin-bottom: 0.9rem;
      } 
    }
    &Adicionar {
      @media (min-width: $medium-size) {
        grid-column: 2 / 3;
        margin-top: 0.2rem;
        margin-bottom: 0.9rem;
      } 
    }
  }

  .concluido {
    @media (min-width: $medium-size) {
      grid-column: 3 / -1;
      align-self: center;
      padding-left: 0.5rem;
    }
  }

  .loader {
    @media (min-width: $medium-size) {
      grid-column: 1 / -1;
      align-self: center;
    }
    &Spinner {
      align-self: center;
    }
    &Check {
      color: #00cf2d;
    }
  }

  .loteCard {
    @media (min-width: $medium-size) {
      grid-column: 1 / -1;
    }
  }

  .tableDeParaMensagem {
    @media (min-width: $medium-size) {
      grid-column: 1 / -1;
    }
  }

  .btnAction {
    padding: 0px 5px 0px 5px;
    margin: 1
  }

  .erroModal {
    color: #FF0000;
  }

  #footerTable {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: space-between;
  }

  .paginacao {
    display: flex;
    justify-content: flex-end;

    &__seletor {
      margin: 0;
    }
  }

  .processarCard {
    @media (min-width: $medium-size) {
        grid-column: 1 / 6;
        padding-bottom: 1.5rem;
    }
  }
</style>